import uuidv1 from 'uuid/v1';

// HEAD DATA
export const headData = {
  title: '', // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: '',
  subtitle: '',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: '',
  paragraphTwo: '',
  paragraphThree: '',
  resume: 'https://drive.google.com/file/d/1R05JOmihRptE7Lzj3tLgk7DSuJuKtcqo/view?usp=sharing', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: uuidv1(),
    img: 'zartis.png',
    title: 'Zartis',
    info: 'Senior Software Engineer - Zartis – Spain',
    info2: `Zartis is a software consulting company that provides individuals/teams for high level expertise to companies.`,
    url: 'https://www.zartis.com/',
    project1Title: 'DIFC Leap :',
    project1: '  Developing a LinkedIn-inspired web application for DIFC Companies.',
    project2Title: 'Apex :',
    project2: ' Assisted company to transition to micro-services architecture.',
    project3Title: ' Ding :',
    project3: ' Provided consultation on modernizing tech-stack',
  },
  {
    id: uuidv1(),
    img: 'Acp.png',
    title: 'Accupoint',
    info: 'Senior Software Engineer - Accupoint – New York, USA',
    info2: `Accupoint provides practice management software for ABA therapy clinics.`,
    url: 'https://accupointmed.com/',
    project1Title: 'Practice Management :',
    project1: ' ERP management product with for therapy and homecare clinics.',
    project2Title: 'Single Sign On :',
    project2: ' SSO integration with the other products, Accupoints’ parent company owns.',
    project3Title: ' Electronic Visit Verification :',
    project3: ' State mandated integration project with government agencies',
  },
  {
    id: uuidv1(),
    img: 'Aygaz.png',
    title: 'Aygaz',
    info: 'Software Consultant - Aygaz – ISTANBUL, Turkey',
    info2:
      'Aygaz is biggest liquid petrol gas seller in Turkey, both commercial and corporate sales.',
    project1Title: 'API Endpoints :',
    project1: ' Developed API endpoints for Aygaz E-commerce site and mobile application',
    project2Title: 'Internal Portals :',
    project2: ' Development for company’s internal portals.',
    url: 'https://www.aygaz.com.tr/en/',
  },
  {
    id: uuidv1(),
    img: 'Logo.png',
    title: 'Logo',
    info: 'Senior Software Developer - Logo Software Company – Izmir, Turkey',
    info2: `Logo is one of the largest software house in Turkey.`,
    url: 'https://www.logo.com.tr/en',
    project1Title: 'HR ERP Products (Tiger HR / Netsis HR) :',
    project1: 'HR ERP web application development having over thousands of corporate users.',
    project2Title: 'Integration Tool: ',
    project2: 'Authored a software to help integration teams setting up ad-hoc application setups.',
  },
  {
    id: uuidv1(),
    img: 'Morhipo.png',
    title: 'Morhipo',
    info: 'Software Engineer - Morhipo.com – Istanbul, Turkey',
    info2: `Morhipo was one of the biggest fashion e-commerce sites in Turkey which has now merged with its parent company Boyner.`,
    url: 'https://www.morhipo.com',
    project1Title: 'Morhipo.com web application: ',
    project1: ' Main morhipo.com web application development.',
    project2Title: 'Ad-hoc integration services: ',
    project2:
      ' Developed and maintained data integrations, both CRM related data integrations of user activity and back-end data integrations with product suppliers.',
    project3Title: 'Morhipo manager application: ',
    project3:
      'Developed manager application that decreases daily operational/ad-hoc requests and reporting needs significantly.',
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'bugrasitemkar@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: uuidv1(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/bugrasitemkar/',
    },
    {
      id: uuidv1(),
      name: 'github',
      url: 'https://github.com/bugrasitemkar',
    },
    {
      id: uuidv1(),
      name: 'medium',
      url: 'https://medium.com/@bugrasitemkar',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: true, // set to false to disable the GitHub stars/fork buttons
};
