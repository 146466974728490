import React, { useState } from 'react';
import { FaArrowUp } from 'react-icons/fa';
import '../../style/tools/scrollArrow.css';

const ScrollArrow = () => {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 200) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 200) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  try {
    window.addEventListener('scroll', checkScrollTop);
  } catch (ex) {
    console.log(ex);
  }

  return (
    <FaArrowUp
      className="scrollTop"
      onClick={scrollTop}
      style={{ display: showScroll ? 'inline' : 'none' }}
    />
  );
};

export default ScrollArrow;
